import React, { useEffect } from 'react';
import {Col, Row} from 'react-bootstrap';
import './intro.css';
import bg from '../../assets/isal.png';
import Aos from 'aos';
import 'aos/dist/aos.css'

const Intro = () => {

  useEffect(()=>{
    Aos.init();
  },[]);

  return (
    <section id="intro" data-aos="fade-right">
        <Row className="desc">
          <Col className="blok">
            <div className="isian">
              <h3>Hello, I'm</h3>
              <h2>Salman Al Farisy Azhar</h2>
              <p>I'm a dedicated student at Bina Nusantara University, specializing in Front-End Development and UI/UX Design. I'm passionate about crafting engaging user experiences through innovative design thinking and creating responsive web solutions. Let's collaborate and embark on a journey of creativity and excellence!.</p>
            </div>
          </Col>
          <Col>
            <img src={bg} alt="foto" className="foto" />
          </Col>
        </Row>
    </section>
  )
}
export default Intro