import React from 'react';
import './copyright.css';

const Copyright = () => {
    return (
        <div className='copy'>
            <h2 className='copyright'>&copy; 2024 Salman Al Farisy Azhar.</h2>
        </div>
    )
}

export default Copyright