import React, { useEffect } from 'react';
import behance from '../../assets/behance.png';
import linkedin from '../../assets/linkedin.png';
import github from '../../assets/github.png';
import './footer.css';
import Aos from 'aos';
import 'aos/dist/aos.css'


const Footer = () => {
  useEffect(()=>{
    Aos.init();
  },[]);

    return (
      <div className='container' data-aos="fade-down">
        <div className="footer">
          <a href='http://be.net/salmanizy' className="footer-link">
            <img src={behance} alt="behance" className="footer-image" />
          </a>
          <a href='https://www.linkedin.com/in/salmanizy/' className="footer-link">
            <img src={linkedin} alt="linkedin" className="footer-image" />
          </a>
          <a href='http://github.com/salmanizy' className="footer-link">
            <img src={github} alt="github" className="footer-image" />
          </a>
        </div>
      </div>
    );
};

export default Footer;