import React, { useEffect } from 'react';
import './skills.css';
import Aos from 'aos';
import 'aos/dist/aos.css'

const Skills = () => {
    useEffect(()=>{
        Aos.init();
      },[]);
    return (
        <section id="skills">
            <span className="skillTitle">What I Do</span>
            <div className="skillBars">
                <div className="skillBar" data-aos="zoom-in">
                    <div className="skillBarText">
                        <h2>Front End Developer</h2>
                        <p>I'm an experienced frontend developer specializing in web application development using React JS and Bootstrap. With expertise in creating visually appealing, responsive, and interactive user interfaces.</p>
                    </div>
                </div>
                <div className="skillBar" data-aos="zoom-in">
                    <div className="skillBarText">
                        <h2>UI/UX Design</h2>
                        <p>I'm a UI/UX designer focused on crafting captivating and intuitive user experiences. Using Figma as my primary tool, I seamlessly blend visually appealing aesthetics with optimal functionality.</p>
                    </div>
                </div>
                <div className="skillBar" data-aos="zoom-in">
                    <div className="skillBarText">
                        <h2>App Design</h2>
                        <p>I'm an experienced app designer specializing in creating captivating and intuitive applications. Leveraging Figma, I seamlessly blend visual aesthetics with optimal functionality, ensuring a seamless user experience.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills