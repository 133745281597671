import React, { useEffect } from 'react';
import { Card, Button, Row, Col} from 'react-bootstrap';
import './portfolio.css';
import lumine from '../../assets/lumine.png';
import moneyset from '../../assets/moneyset.png';
import vinyl from '../../assets/vinyl.png';
import Aos from 'aos';
import 'aos/dist/aos.css'

const Portfolio = () => {
  const projects = [
    {
      title: 'Vinyl',
      description: 'Virtual jukebox shares song queue.',
      image: vinyl,
      url: 'https://www.behance.net/gallery/199926833/vinyl',
    },
    {
      title: 'MoneySet',
      description: 'Moneyset is stock mobile stock app.',
      image: moneyset,
      url: 'https://www.behance.net/gallery/171352993/MoneySet',
    },
    {
      title: 'Lumine Dashboard',
      description: 'Lumine dashboard is Account Analytics.',
      image: lumine,
      url: 'https://www.behance.net/gallery/165412037/Lumine-Dashboard',
    }
  ];

  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="portfolio">
        <span className='title'>Portfolio</span>
        <Row className="portfolio-row">
            {projects.map((project, index) => (
                <Col key={index} className="portfolio-col" data-aos="zoom-in">
                    <Card
                        className="portfolio-card"
                        ref={(cardRef) => (project.cardRef = cardRef)}>
                        <Card.Img variant="top" src={project.image} />
                        <Card.Body>
                            <Card.Title>{project.title}</Card.Title>
                            <Card.Text>{project.description}</Card.Text>
                            <Button className="btn-view-project" href={project.url}>
                                View Project
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </div>
  );
};

export default Portfolio;