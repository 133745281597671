import React, { useEffect } from 'react';
import './viewmore.css';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Button } from 'react-bootstrap';


const Viewmore = () => {
  useEffect(()=>{
    Aos.init();
  },[]);

    return (
      <div className='container' data-aos="fade-down">
        <div className="viewmore">
            <Button variant="dark" href='https://salmanizy.com/project' className="btn-viewmore">View More</Button>
        </div>
      </div>
    );
};

export default Viewmore;